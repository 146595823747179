import React from 'react';


export const Transition = () => (
            <div className="transition-bg">
                <div className="transition-wrapper">
                    <div className="transition-container"></div>
                </div>
                
            </div>
)