import React from 'react'
import { NavigationBar } from './components/Navigation';

export const FourOFour = () => {
    return (
        <div>
        <NavigationBar />
            <h2 id="errorPage">404 Error - Page Not found</h2>
        </div>
    )
}