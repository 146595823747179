import React from 'react'; 


export const LeftLinks = () => {
        return (
            <div className="left-links">
            <svg id="copyrightSVG" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.425 37.425">
                <path id="copyright" d="M18.712,0A18.713,18.713,0,1,0,37.425,18.713,18.734,18.734,0,0,0,18.712,
                0Zm0,32.322A13.609,13.609,0,1,1,32.322,18.713,13.625,13.625,0,0,1,18.712,32.322Zm5.055-10.5,2.759,2.563L25.137,
                25.6a9.514,9.514,0,0,1-6.263,2.363,9.277,9.277,0,0,1-9.362-9.168,9.267,9.267,0,0,1,9.362-9.147,9.475,9.475,0,0,
                1,6.242,2.341l1.339,1.172-2.644,2.645-1.174-1.037a5.664,5.664,0,0,0-3.763-1.383,5.525,5.525,0,0,0-5.623,5.408,
                5.535,5.535,0,0,0,5.623,5.43,5.671,5.671,0,0,0,3.763-1.4Z" fill="transparent"/>
            </svg>

                <span>2021 Shaun Bolak Design</span>
            </div>

        ) 
}