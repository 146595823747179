import React from 'react'; 


export const RightLinks = () => {
        return (
            <React.Fragment>
                <div>
                    <svg className="logo-small" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.46 48.16">
                            <g id="Layer_2" data-name="Layer 2">
                                <g id="background">
                                    <path className="cls-1" d="M7.05,26.28,24,5.6,4,26.7a.1.1,0,0,0,.1.17l15.72-3.13a.11.11,0,0,1,.1.17S.3,47.89.38,47.78L16.29,24.44"/>
                                    <path className="cls-1" d="M21.91,20.64S36.17.28,36.09.38L18.85,21a.1.1,0,0,0,.1.17l14.18-2.53a.1.1,0,0,1,.1.17S14.86,41.11,14.93,41l15-21.75"/>
                                    <circle className="cls-2" cx="18.75" cy="23.25" r="15.23"/>
                                </g>
                            </g>
                    </svg>
                </div>

                <div className="right-links">
                    {/* github logo/link */}
                    <a href="https://github.com/shonb6570" target="_blank" rel="noreferrer">
                        <svg className="social-links" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.183 40.167">
                            <path id="github" d="M20.592,0a20.593,20.593,0,0,0-6.512,40.128c1.03.18,1.416-.438,
                            1.416-.978,0-.489-.026-2.111-.026-3.835-5.174.952-6.512-1.261-6.924-2.42a7.488,7.488,
                            0,0,0-2.111-2.909c-.721-.386-1.75-1.338-.026-1.364,1.622-.026,2.78,1.493,3.166,2.111a4.4,
                            4.4,0,0,0,6,1.7,4.332,4.332,0,0,1,1.313-2.754c-4.582-.515-9.369-2.291-9.369-10.167a8.014,
                            8.014,0,0,1,2.111-5.534A7.4,7.4,0,0,1,9.832,8.52s1.725-.541,5.663,2.111a19.414,19.414,0,0,
                            1,10.3,0c3.938-2.677,5.663-2.111,5.663-2.111a7.4,7.4,0,0,1,.206,5.457,7.967,7.967,0,0,1,2.111,
                            5.534c0,7.9-4.813,9.652-9.395,10.167a4.876,4.876,0,0,1,1.39,3.809c0,2.754-.026,4.968-.026,5.663,
                            0,.541.386,1.184,1.416.978A20.6,20.6,0,0,0,20.592,0Z" fill=""/>
                        </svg>
                    </a>

                    <a href="https://www.linkedin.com/in/shaun-bolak-8b817679" target="_blank" rel="noreferrer">
                        <svg className="social-links" xmlns="http://www.w3.org/2000/svg" width="39.091" height="39.091" viewBox="0 0 39.091 39.091">
                            <path id="linkedin" d="M0,2.8A2.836,2.836,0,0,1,2.871,0H36.22a2.836,2.836,0,0,1,2.871,2.8V36.291a2.836,
                            2.836,0,0,1-2.871,2.8H2.871A2.836,2.836,0,0,1,0,36.291ZM12.077,32.724V15.072H6.211V32.724ZM9.145,
                            12.661a3.059,3.059,0,1,0,.039-6.1,3.058,3.058,0,1,0-.078,6.1ZM21.136,32.724V22.866a3.983,3.983,0,0,
                            1,.2-1.432,3.209,3.209,0,0,1,3.01-2.145c2.123,0,2.971,1.617,2.971,3.992v9.443h5.866V22.6c0-5.424-2.893-7.945-6.753-7.945a5.829,
                            5.829,0,0,0-5.289,2.915v.061H21.1l.039-.061v-2.5H15.272c.073,1.656,0,17.652,0,17.652Z" fill=""/>
                        </svg>
                    </a>
                </div>
            </React.Fragment>
        ) 
}