import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/App.scss';
import { Layout } from './components/Layout';
import { Home } from './Home';
import { About } from './About';
import { Work } from './Work';
import { HSF } from './HSF';
import { FourHire } from './FourHire';
import { WriterAccess } from './WriterAccess';
import { CaribLib } from './CaribLib';
import { Design } from './Design';
import { Contact } from './Contact';
import { FourOFour } from './FourOFour';
import { Footer } from './components/Footer';




class App extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="site-bg">    
              <Router>
                <Layout>
                  <Routes>
                  
                    <Route exact path='/' element={ <Home /> } />
                    <Route path='/about' element={ <About /> } />
                    <Route path='/work' element={ <Work /> } />
                    <Route path='/hsf' element={ <HSF /> } />
                    <Route path='/fourhire' element={ <FourHire /> } />
                    <Route path='/writeraccess' element={ <WriterAccess /> } />
                    <Route path='/caribbean-library' element={ <CaribLib /> } />
                    <Route path='/design' element={ <Design /> } />
                    <Route path='/contact' element={ <Contact /> } />
                    <Route element={ <FourOFour />} />

                  </Routes>
                </Layout>
              </Router>
            
          <Footer />
        </div>
      </React.Fragment> 
    )
  }
}

export default App;
