import React from 'react';
import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import awesomeCoGif from '../video/apiDatabase.mp4';


export const ProjectOne = () => {
    return (
                    <Container className="project-examples">
                        {/* <!-- first project (user database) --> */}
                            <div className="d-flex flex-wrap align-items-center">
                                <div className="awesomeCo hov" id="awesomeCoGif">
                                    <div className="text-center text-xl-right gif-container">
                                        <video className="gif" autoPlay muted loop>
                                            <source src={awesomeCoGif} type="video/mp4" /> ;     
                                        </video>

                                        {/* <!-- overlay links --> */}
                                            <div className="overlay text-center">
                                                <div className="link-container">
                                                    <a href="./links/awesomeCo/index.html" target="_blank">
                                                        <p className="project-links">See it Live</p>
                                                    </a>

                                                    <hr className="linkHR" />

                                                    <a
                                                        href="https://github.com/shonb6570/AwesomeCo"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <p className="project-links">View Code</p>
                                                    </a>
                                                </div>
                                            </div>
                                        {/* <!-- /overlay links --> */}
                                        
                                    </div>
                                </div>

                                <div className="awesomeCo" id="awesomeCoCopy">
                                    {/* <!-- awesomeCo SVG logo --> */}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        id="logo"
                                        viewBox="0 0 428.38 118.04"
                                    >
                                        <g data-name="Layer 1">
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                fill="transparent"
                                                d="M149 .69c-1.72 11.59-6.18 22.42-10.8 33.1s-9.58 20.9-15.06 31c-5.08 9.38-10.19 19.82-17.26 27.89-6.8 7.77-18.41 14-28.54 15.85-1.6.29-.1 9.76 1.34 9.5 9.72-1.74 20.21-7.47 27.26-14.38 8.17-8 13.64-19.81 19-29.78q8.45-15.55 15.44-31.87c4.61-10.73 9.07-21.64 10.8-33.26a15.17 15.17 0 00-.54-6.56c-.16-.5-1.37-3.54-1.67-1.51z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                fill="transparent"
                                                d="M148.47 5.35c-6.64 19.24-9.72 39.38-12 59.53-1 9.11-2.95 18.78-1.8 27.94.85 6.8 3.25 15.78 10.38 18.33.11 0 .92-7.19-1.12-7.92-10.23-3.67-7.05-18-6.15-26.42.8-7.52 1.65-15 2.75-22.52a230.36 230.36 0 019.78-42.22c.53-1.53.06-4-.45-5.47-.21-.61-.86-2.8-1.39-1.25z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                fill="transparent"
                                                d="M140.57 101.73c-32.82-22.32-70.76-35.65-110.08-40.4-.68-.09-.7 7.7 1.11 7.92 39.16 4.73 76.89 18 109.58 40.19.43.28 1.07-6.57-.61-7.71zM226.32 80c3.46-.14 5.44-3.29 7.74-5.48.5-.47-1.35-.59-1.47-.59a3.68 3.68 0 00-2.4.68c-.79.74-1.51 1.55-2.27 2.31-.48.48-1 1-1.48 1.41a7.91 7.91 0 01-.63.52c-.78.58 0-.06 1.45-.11-.5 0-2.2.06-2.4.67s1.15.6 1.46.59zM175.77 76.78c9.5-.13 19 0 28.48.15 2.5 0 5.91-1.94 1.5-2-9.49-.17-19-.28-28.48-.15-2.48 0-5.92 2.08-1.5 2zM139.24 75.12c.52 0 1.81-.41 2.23-.15.18.1-2.24-.06-1.13 0 .78.07 1.57.07 2.34.17 1.29.15 3.78.35 4.42 1.74.93 2 8.39.12 8-.84-1.09-2.38-4.57-2.86-6.87-3.18a43.6 43.6 0 00-7.07-.3c-1 0-4.39.12-4.8 1.34s2.3 1.21 2.93 1.18z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                fill="transparent"
                                                d="M8.11 68.23C4.18 70.91-1.25 75 .26 80.6c1.21 4.54 8 5.29 11.8 5.76 12.37 1.53 25.22-.91 37.35-3.19 15.13-2.85 30.09-5.83 45.47-7.1 16.53-1.35 32.94-.75 49.49-.82 3.1 0 7.39-2.55 1.87-2.52-27.29.11-54-.1-81 4.81C53 79.77 40.85 82.77 28.43 83.9c-5.36.49-12 1.1-17.09-1.18-2.85-1.29-3.78-3.38-3-6.39.9-3.71 3.84-5.6 6.81-7.62 1.46-1-.95-1.38-1.6-1.48a8.75 8.75 0 00-5.4 1z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                fill="transparent"
                                                d="M9 75.25c3-3.64 6.7-6.57 11.6-6.62 3.81 0 7.61.51 11.41.67 1.21 0 4.63.19 5.39-1s-.24-1.43-1.59-1.49C23 66.28 10.48 64.42 1.24 75.42.6 76.18 7.53 77 9 75.25z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                fill="transparent"
                                                d="M42.77 63.35c-8.11-1.8-16.57-3.41-24.69-.77-6.59 2.14-13.28 6.85-16.84 12.84-.69 1.15 6.65 1.65 7.76-.17 2.62-4.39 6.55-7.44 11-9.86 5.53-3 11.54-1.06 17.36.24 1.61.35 9.13-1.47 5.45-2.28z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                fill="transparent"
                                                d="M19.83 66.7c2.42-.07 6-.85 8.16.27 1.49.76 4 .32 5.49-.14.81-.25 2.77-1 1.26-1.79-3.77-1.91-9-1-13-.87-1 0-4.4.13-4.81 1.35s2.3 1.19 2.93 1.18z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                fill="transparent"
                                                d="M18 67.08c3.11 0 7.4-2.53 1.88-2.53-3.11 0-7.4 2.53-1.88 2.53zM26.09 65.61c3.11 0 7.4-2.53 1.87-2.53-3.11 0-7.39 2.53-1.87 2.53z"
                                            />
                                            <path
                                                id="logoFill"
                                                d="M62 73.81c.45.15 10 3.66 10 3.66s-18.79 3.29-31.35 5c-4.26.6-8.21 1.06-11.75 1.34a79.1 79.1 0 01-8.78.27c-1.49 0-2.68-.11-3.21-.13-4.95-.12-9.54-1.74-9.52-6.17 0-4.06 3.16-7.42 7.44-9.85 6.47-3.64 40.62 3.72 47.17 5.88z"
                                                fill="transparent"
                                                stroke="transparent"
                                                strokeMiterlimit="10"
                                                strokeWidth=".5"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                fill="transparent"
                                                d="M149.87 80.53c1.74 4.46 3.6 8.86 5.21 13.37.41 1.13 4.92-2.74 5-3.81a77.74 77.74 0 013.83-18.85c.44-1.3-1.17-.55-1.72-.28a6.91 6.91 0 00-3.14 3.04 80.56 80.56 0 00-4 19.61l5-3.81c-1.62-4.51-3.48-8.92-5.21-13.38-.3-.77-5.58 2.4-4.93 4.09z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                fill="transparent"
                                                d="M159.75 75.17c2 9 3.35 18.21 5.37 27.25.32 1.45 5-2.18 5.08-3.55.35-9.23 2-22.29 9-29.1 3.14-3.08-.7-2.09-2.49-.34-8.42 8.23-11.13 21.66-11.57 33l5.08-3.55c-2-9-3.35-18.21-5.37-27.24-.29-1.3-5.41 2-5.08 3.54zM229.61 72.69a197.26 197.26 0 01.39 22.94c0 .94 5-1.8 5.07-3.54a195.24 195.24 0 00-.34-23c-.1-1.13-5.23 1.89-5.08 3.55z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                fill="transparent"
                                                d="M235.89 81.75l1.87-6.3c.26-.88.51-1.78.8-2.66.5-1.46 1.38-1.81-.55-.74l-1.83.1a89.87 89.87 0 002.42 21.3c.31 1.32 5.43-2.06 5.08-3.55a89.79 89.79 0 01-2.42-21.29c0-.89-1.57 0-1.82.1-5.86 3.24-6.66 9.9-8.42 15.82-.52 1.76 4.45-1.35 4.87-2.78z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                fill="transparent"
                                                d="M243.22 89.94c5.28-5.91 2.13-15.53 6.38-22.21l-4.71 2.69c-.13 7.52-1.37 15.79.25 23.18.7 3.16 1.43 9.55 4 11.76 1.23 1 5.79-3.35 4.93-4.08-2.67-2.28-3.41-8.92-4.07-12.09-1.39-7.07-.19-15.15-.06-22.32 0-1.53-4.42 2.22-4.72 2.7-1.82 2.87-2.59 6.2-3.27 9.51-.64 3.15-.09 9.17-2.3 11.64-2.7 3 2.49.41 3.54-.78zM196.7 74.18c-1 1.52-3 3.85-2.28 5.85.57 1.66 1.74 1.2 3.11 1.55a6.9 6.9 0 013.92 2c1.43 1.53 3.71 6.32 2.32 8.49-.74 1.14-4.05.24-5-.33a19.37 19.37 0 01-3.77-3.68c-1-1.07-5.82 3.11-4.92 4.09 2.85 3.09 5.4 5.65 9.77 4.19 2.93-1 6.38-2.93 8.12-5.56s1.24-6.23.05-8.86a8 8 0 00-4-4.23c-.87-.35-2.64 0-3.24-.44-1.86-1.22-.52-3.72.32-4.91s0-1.14-.87-1c-1.21.12-2.83 2-3.49 2.89zM185.58 67.72c-8.5 4.19-13.91 13.77-11 23.33 3.33 10.75 13.68-.09 16.61-5.12 1.53-2.63-3.68.68-4.35 1.84-1.38 2.37-2.37 4.26-5.19 2.41a5.58 5.58 0 01-2.28-4.05c-.76-4-.83-12.32 3.54-14.48 1.09-.54 3.1-1.63 3.48-2.89.26-.83.51-1.73-.86-1z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                fill="transparent"
                                                d="M192.85 71.77a3.9 3.9 0 01-2.57-2.37 5.62 5.62 0 00-1.28-1.87c-.89-.7-2-.3-2.95 0a7.73 7.73 0 00-3.48 2.89c-.66 1-.24 1.43.87 1s1.93 2.09 2.32 2.77a4.13 4.13 0 002.66 1.83c1.06.33 2.8-1.34 3.46-2 .33-.32 2-2 1-2.33zM162 74.16c1.38 0 2.82-3.68 1.29-3.68s-2.83 3.68-1.29 3.68z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                fill="transparent"
                                                d="M162.46 74.45c1.38 0 2.82-3.67 1.29-3.67s-2.83 3.67-1.29 3.67zM227.74 79.16c1.39 0 2.83-3.68 1.29-3.68-1.38 0-2.82 3.68-1.29 3.68z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                d="M229 77.8c1.38 0 2.83-3.67 1.29-3.67-1.38 0-2.82 3.67-1.29 3.67z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                d="M228.45 77.75a1.63 1.63 0 001.67-.33 5 5 0 00.69-.61 4 4 0 01.35-.36l-.33.28a2.11 2.11 0 01.29-.2l-.33.18a1.15 1.15 0 01.31-.11l-.31.06a.77.77 0 01.26 0l-.28-.07a.61.61 0 01.18.09l-.22-.19a.44.44 0 01.12.15.67.67 0 00.81.19 1.34 1.34 0 00.49-.32 2.15 2.15 0 00.48-.52 3.41 3.41 0 00.62-1.42 1.56 1.56 0 00-.13-1.1 1.24 1.24 0 00-.36-.36 1.16 1.16 0 00-.48-.14 1.41 1.41 0 00-.54.07 1.39 1.39 0 00-.26.1 4.06 4.06 0 00-1.08.76c-.17.17-.32.36-.5.52l.33-.29a1.54 1.54 0 01-.29.21l.33-.18-.2.08.32-.07a.66.66 0 01-.24 0l.28.06h-.14a1 1 0 00-.92.18 2.19 2.19 0 00-.49.47 2.82 2.82 0 00-.43.65 3 3 0 00-.39 1.39c0 .31.07.71.39.86z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                d="M229.74 76.71a1.94 1.94 0 00.59 0 3.38 3.38 0 00.89-.2 1.43 1.43 0 00.28-.13 6.38 6.38 0 00.57-.32c.19-.14.37-.31.55-.47a1.81 1.81 0 00.24-.24 2.62 2.62 0 00.45-.6 2.82 2.82 0 00.35-.72 2 2 0 00.15-.69 1.31 1.31 0 000-.59l-.13-.29a.57.57 0 00-.49-.26l-.31.06a1.94 1.94 0 00-.66.47 4 4 0 01-.29.29l.33-.29a2.34 2.34 0 01-.41.29l.33-.18a2.54 2.54 0 01-.6.22l.31-.07a2 2 0 01-.85 0 .88.88 0 00-.47.16 1.42 1.42 0 00-.5.37 2.47 2.47 0 00-.45.61 3.16 3.16 0 00-.36.71l-.12.47a1.84 1.84 0 000 .81l.12.3a.58.58 0 00.5.25z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                fill="transparent"
                                                d="M231.1 75.92l.17-.11-.33.18a1.34 1.34 0 01.38-.14l-.31.06a1.35 1.35 0 01.53 0l-.28-.06h.13a.47.47 0 00.43 0 .9.9 0 00.49-.21 2.19 2.19 0 00.49-.47 2.76 2.76 0 00.43-.64l.21-.49a2.58 2.58 0 00.17-.91v-.37a.69.69 0 00-.34-.49l-.41-.1h-.16a1.29 1.29 0 00-.34 0 3.1 3.1 0 00-.6.15 4.23 4.23 0 00-.47.25l-.15.1a2 2 0 00-.48.47 2.8 2.8 0 00-.44.64 2.38 2.38 0 00-.27.73 1.89 1.89 0 00-.11.67v.37a.78.78 0 00.34.49l.28.06a1.22 1.22 0 00.64-.24zM199.08 74.6c.7 0 1.64-1.7.94-1.7s-1.64 1.7-.94 1.7z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                fill="transparent"
                                                d="M197.68 75.18c1.79 0 5.12-4.33 4.43-4.33-1.79 0-5.11 4.33-4.43 4.33zM335.48 30.33c-3.92-15.62-24.84-6-33 .67-12.16 9.89-17.48 26.16-17.95 41.4-.4 13.15 3.17 31.29 18.68 34.19 13.14 2.46 21.85-11 26.12-21.34.46-1.12 1.81-5.12.42-6s-2.63 1.49-3.09 2.6c-3.52 8.53-10.92 17.74-21.3 15.43-11.92-2.68-16.19-15.28-16.47-26.1-.32-12.6 3.82-24.77 14.11-32.54 6.68-5 25.18-13.7 28.41-.86.54 2.14 2.37-.15 2.88-1a9.29 9.29 0 001.19-6.49z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                fill="transparent"
                                                d="M344.72 73.27c-1.42-5.74-7.54-4.42-10.44-.64-3 3.94-4.53 11-2 15.58 2.83 5.09 9.51 4 12.23-.52 1.78-3 4.45-13.68-1.64-13.93-2-.09-3.17 6.08-1.78 6.14 1.89.08 2.38 1.45 2.14 3.11-.29 2-1.55 2.4-3.4 2.44a5.41 5.41 0 01-5.37-3.45c-.95-2.5-.23-5.17 2.36-6.25 2.31-1 4.57-.06 5.19 2.45.35 1.43 1.58-.1 1.92-.64a6.19 6.19 0 00.79-4.33z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                fill="transparent"
                                                d="M341.57 80.48c26.49-.58 52.78 1.94 78.92 6.1 2 .31 5 .39 6.75-.71 1.27-.79 1.85-1.7-.21-2-27.56-4.38-55.29-7-83.21-6.38-1.06 0-5.46.2-5.77 1.61s2.59 1.43 3.52 1.41zM348.12 95.68c-.57-1.5.09-3.8 1.69-4.44a2.09 2.09 0 01.43-.11 1.13 1.13 0 01.32 0c.35.08.23-.08-.34-.47v.62a3 3 0 01-.22.72c-.57 1.18-1.86 1.47-3 1.68s.73 2.75 1.78 2.56c1.8-.34 3.56-1.08 3.89-3.09a4.18 4.18 0 00-2.82-4.44c-3.37-1.1-5 3.53-4 6a2.82 2.82 0 001.52 1.67c.55.19 1.05-.08.8-.73zM149.12 77a4.59 4.59 0 002.29 3.21c.14.1.39-.19.45-.29s.29-.41.15-.51c-1-.73-2.12-1.65-2.22-3 0-.2-.21-.1-.31 0a.94.94 0 00-.36.59z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                d="M148.62 77.12a11.7 11.7 0 001.74 3.63c.12.16.79-.54.6-.8a11.41 11.41 0 01-1.68-3.54c-.05-.19-.34.08-.4.13s-.32.38-.26.58z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                d="M148.23 76.8l.91 1.42a.11.11 0 00.15 0 .67.67 0 00.25-.19 1 1 0 00.22-.32c0-.06.09-.19.05-.25l-.92-1.42c0-.06-.07-.07-.15 0a.63.63 0 00-.24.19 1 1 0 00-.22.32c0 .06-.09.18-.05.25z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                d="M148.19 77.46l2.23 3.23c.11.16.79-.53.6-.79l-2.23-3.24c-.11-.16-.79.53-.6.8z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                d="M149.93 77.38a6.27 6.27 0 00.86 2.26c.1.16.32 0 .4-.13a1.14 1.14 0 00.22-.32c0-.06.08-.19 0-.25a6.39 6.39 0 01-.86-2.27c0-.07-.1-.06-.15 0a.67.67 0 00-.25.19.76.76 0 00-.26.57z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                d="M150.66 76.14a3.19 3.19 0 00.39 2.4c.11.16.32-.05.4-.14a1 1 0 00.22-.32c0-.06.08-.19 0-.25a2.89 2.89 0 01-.41-1 3.05 3.05 0 01-.05-.52v-.3a.59.59 0 010-.14.22.22 0 010-.08v-.2c0-.05-.15 0-.19 0a1 1 0 00-.43.56z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                d="M151.09 76.79a1.83 1.83 0 00.73 1.78h.19a.88.88 0 00.26-.25.94.94 0 00.17-.31v-.2a1.3 1.3 0 01-.41-.4 1.76 1.76 0 01-.25-.58v-.15a.28.28 0 010-.09 1.82 1.82 0 010-.33.11.11 0 00-.08-.13c-.08 0-.18.07-.23.12a1 1 0 00-.36.58zM253.52 77.86c8.12-.1 16.22 0 24.33.22 1.59 0 7.06-1.89 3-2-8.62-.21-17.24-.35-25.87-.25-2.48 0-5.92 2.07-1.5 2z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                fill="transparent"
                                                d="M262.33 68.77a20 20 0 00-11 23c2.61 11 13.64.35 16.7-4.76 1.55-2.6-3.67.7-4.35 1.85-4.43 7.39-7.23 1-7.67-3.16-.39-3.77-.3-11 3.74-13 1.09-.52 3.1-1.64 3.48-2.88.26-.85.52-1.71-.86-1zM204.87 67.9a19.49 19.49 0 00-10.4 10.95c-.48 1.28 1.21.56 1.72.28a7.33 7.33 0 003.15-3.06 12.29 12.29 0 011.66-3.28 5.06 5.06 0 01.66-.73c-.52.46.42-.16.57-.22a8 8 0 003.51-2.84c.59-1 .28-1.53-.87-1z"
                                            />
                                            <path
                                                className="logo-stroke"
                                                stroke="transparent"
                                                fill="transparent"
                                                d="M211.1 72a3.85 3.85 0 01-2.56-2.36 5.66 5.66 0 00-1.28-1.87c-.9-.71-2-.3-2.95 0a7.58 7.58 0 00-3.49 2.89c-.66 1-.24 1.43.87 1s1.93 2.09 2.32 2.78a4.2 4.2 0 002.66 1.82c1.06.33 2.8-1.34 3.46-2 .33-.33 2-2 1-2.34zM269.18 72.44a3.84 3.84 0 01-2.56-2.37 5.62 5.62 0 00-1.28-1.87c-.9-.7-2-.3-2.95 0a7.62 7.62 0 00-3.49 2.88c-.65 1-.23 1.43.87 1s1.93 2.09 2.32 2.77a4.18 4.18 0 002.66 1.83c1.07.33 2.8-1.34 3.46-2 .34-.33 2-2 1-2.34zM224.23 73.81c-1.42-5.75-7.54-4.42-10.44-.65-3 3.94-4.53 11-2 15.58 2.83 5.09 9.51 4 12.23-.52 1.77-3 4.45-13.68-1.64-13.93-2-.08-3.17 6.09-1.78 6.15 1.89.08 2.38 1.45 2.14 3.1-.29 2-1.55 2.4-3.4 2.44a5.43 5.43 0 01-5.34-3.41c-.94-2.5-.22-5.17 2.37-6.24 2.31-1 4.57-.07 5.19 2.44.35 1.43 1.58-.1 1.92-.64a6.17 6.17 0 00.79-4.32z"
                                            />
                                        </g>
                                    </svg>
                                    {/* <!-- /awesomeCo SVG logo --> */}
                                    <h3 className="sub-heading">
                                        User Database from an API <br />
                                        <Badge className="badgeFont mr-2 mt-4 mb-1">3rd party API</Badge>
                                        <Badge className="badgeFont mr-2 mb-1">JS Plugins</Badge>
                                        <Badge className="badgeFont mr-2 mb-1">JavaScript</Badge>
                                        <Badge className="badgeFont mr-2 mb-1">Fetch</Badge>
                                        <Badge className="badgeFont mr-2 mb-1">Sass</Badge>
                                        <Badge className="badgeFont mb-2">SVG Animation</Badge>
                                    </h3>
                                    <p className="project-copy">
                                        An example of an employee directory generated by
                                        communicating with a third-party API. Created with Javascript
                                        and Fetch. Search functionality and individual employee details
                                        enabled with third-party Javascript plugins.
                                    </p>
                                </div>
                            </div>
                        {/* <!-- /first project (user database)--> */}

                    </Container>
    )
}


	
